import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import NavbarMobile from '../NavbarMobile';
import GlobalStyles from '../../styles/GlobalStyles';
import Overview from './Overview';
import { ArrowUpward } from '@mui/icons-material';


const App: React.FC = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <GlobalStyles />
      <div style={{ 
        backgroundColor: '#f5f5f5', 
        display: 'flex', 
        flexDirection: 'row', 
        height: '100vh' 
      }}>
        {window.innerWidth > 768 ? 
          <>
            <Navbar />
            <div style={{ 
              marginLeft: '250px', 
              overflowY: 'auto', 
              flex: 1, 
              height: '100vh' 
            }}>
              <Overview />
            </div>
          </>
          :
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}> 
            <NavbarMobile />
            <Overview /> 
          </div>
        }
      </div>

      {/* Botão flutuante de voltar ao topo */}
      {showScrollButton && (
        <button 
          onClick={scrollToTop} 
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '10px',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: '#111111ea',
            color: 'white',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 8px 6px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer'
          }}
        >
          <ArrowUpward  />
        </button>
      )}
    </>
  );
};

export default App;
