import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { useLanguage } from "../../contexts/LanguageContext";

const Container = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    max-width: 600px;
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: flex-start;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.8rem;
  }
`;

const Email = styled.a`
  color: black;
  text-decoration: none;
  font-size: 1rem;
  
  display: block;
  text-align: flex-start;
`;

const Form = styled.form`
 text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Row = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 12px;
  border: 1px solid black;
  font-size: 1rem;
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid black;
  font-size: 1rem;
  height: 120px;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 100px;
    padding: 10px;
  }
`;

const SubmitButton = styled.button`
  background: black;
  color: white;
  padding: 15px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  transition: 0.3s ease;

  &:hover {
    background: #333;
  }

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const Disclaimer = styled.p`
  font-size: 0.9rem;
  color: black;
  text-align: center;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const Phone = styled.a`
  color: black;
  text-decoration: none;
  font-size: 1rem;

  display: block;
  text-align: flex-start;
`;

const texts = {
  EN: {
    title: "CONTACT",
    email: "E-mail",
    subject: "Subject",
    message: "Message",
    name: "Name",
    lastname: "Lastname",
    send: "SEND",
    desc: "We will only use the information provided on this form to contact you regarding your request. Please submit the form only if you agree with our privacy policy.",
  },
  PT: {
    title: "CONTACTO",
    email: "E-mail",
    subject: "Assunto",
    message: "Mensagem",
    name: "Nome",
    lastname: "Sobrenome",
    send: "ENVIAR",
    desc: "Usaremos apenas as informações fornecidas neste formulário para entrar em contato com você a respeito de sua solicitação. Por favor, envie o formulário apenas se concordar com a nossa política de privacidade.",
  },
};

const ContactForm: React.FC = () => {
  const { language } = useLanguage();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data: any) => {
    emailjs
      .send(
        "service_s7bovfp", // Service ID do EmailJS
        "template_f2w6llw", // Template ID do EmailJS
        {
          to_name: "Lucas Almeida", // Nome do destinatário
          from_name: data.name + " " + data.lastname,
          from_email: data.email,
          subject: data.subject,
          message: data.message,
        },
        "qlAx0C8cPURTKWcN9" // Public Key do EmailJS
      )
      .then(
        () => {
          alert("Email enviado com sucesso!");
          reset();
        },
        (error: any) => {
          console.error("Erro ao enviar email:", error);
          alert("Erro ao enviar email, tente novamente.");
        }
      );
  };

  return (
    <Container>
      <Title>{texts[language].title}</Title>
      {/* eslint-disable-next-line */}
      <a>Manuel Guerra Pereira</a>
      <Email href="mailto:mguerrapereira@gmail.com">mguerrapereira@gmail.com</Email>
      <Phone href="tel:+351 918 939 596">+351 918 939 596</Phone>
      {/* eslint-disable-next-line */}
      <a>Lisboa, Portugal</a>
        <Title style={{marginTop:'20px'}}>E-MAIL</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Input {...register("name", { required: true })} type="text" placeholder={texts[language].name} />
          <Input {...register("lastname", { required: true })} type="text" placeholder={texts[language].lastname} />
        </Row>
        <Row>
          <Input {...register("email", { required: true })} type="email" placeholder={texts[language].email} />
          <Input {...register("subject", { required: true })} type="text" placeholder={texts[language].subject} />
        </Row>
        <TextArea {...register("message", { required: true })} placeholder={texts[language].message} />
        <Disclaimer>{texts[language].desc}</Disclaimer>
        <SubmitButton type="submit">{texts[language].send}</SubmitButton>
      </Form>
    </Container>
  );
};

export default ContactForm;
