import React from 'react';
import styled from 'styled-components';
import AlertBox from './AlertBox';
import PhotoTable from './PhotoTable';


interface PhotosArchiveProps {
}

const PhotosArchive: React.FC<PhotosArchiveProps> = () => {
  return (
    <ArchiveContainer>
      <header>
        <ArchiveTitle>Photos Archive</ArchiveTitle>
        <ArchiveDescription>List of all photos already added</ArchiveDescription>
      </header>
      <AlertBox />
      <PhotoTable />
   
    </ArchiveContainer>
  );
};

const ArchiveContainer = styled.main`
margin-top: 30px;
  border-radius: 10px;
  background-color: var(--white, #fff);
  display: flex;
  width: 100%;
  max-width: 1120px;
  flex-direction: column;
  color: var(--neutral-800, #262626);
  padding: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const ArchiveTitle = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

const ArchiveDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
`;

export default PhotosArchive;