import React from 'react';
import styled from 'styled-components';

import Sidebar from './Sidebar';
import AddPhotoForm from './AddPhotoForm';
import PhotosArchive from './PhotosArchive/PhotosArchive';
const ManagerPhotos: React.FC = () => {
  return (
    <Dashboard>
    
      <MainContent>
        <Sidebar />
        <ContentArea>
          <PageTitle>Add Photo - Real Life</PageTitle>
          <PhotosArchive />
          <AddPhotoForm />
          <Footer href="https://www.linkedin.com/in/lucas-almeida-portilho-barcelos-9a94a3136/">Made by Lucas Portilho</Footer>
        </ContentArea>
      </MainContent>
    </Dashboard>
  );
}

const Dashboard = styled.div`
  background-color: var(--neutral-100, #f5f5f5);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainContent = styled.main`
  display: flex;
  gap: 0px;
  width: 100%;


  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const ContentArea = styled.section`
  display: flex;
  align-items:  center;
  flex-direction: column;
  width: 100%;
  margin-left: 0px;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const PageTitle = styled.h1`
  align-items: start;
  background-color: var(--white, #fff);
  display: flex;
  width: 100%;
  font-size: 22px;
  color: var(--neutral-800, #262626);
  font-weight: 600;
  padding: 22px 30px;

  @media (max-width: 991px) {
    padding: 22px 20px;
  }
`;

const Footer = styled.a`
outline: none;
text-decoration: none;
  align-self: center;
  margin-top: 65px;
  padding-bottom: 20px;
  color: #000;
  font: 400 14px Abel, sans-serif;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export default ManagerPhotos;