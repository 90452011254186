import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// Declaração da fonte no estilo global
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'ZT Gatha';
    src: url('../../../public/font/ZTGatha.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

interface AboutCardProps {
  title: string;
  content: string;
}

const AboutCard: React.FC<AboutCardProps> = ({ title, content }) => {
  return (
    <>
      <GlobalStyle />
      <CardWrapper>
        <CardTitle>{title}</CardTitle>
        <CardContent>{content}</CardContent>
      </CardWrapper>
    </>
  );
};

// Estilo do Card Wrapper
const CardWrapper = styled.article`
  flex-grow: 1;

  width: 288px;
`;

// Estilo do título com fonte ZT Gatha
const CardTitle = styled.h2`
  color: #5b5c5c;
  font-size: 24px;
  margin-bottom: 5px;
`;

// Estilo do conteúdo com fonte ZT Gatha
const CardContent = styled.p`
  font-size: 14px;
`;

export default AboutCard;
