import React from 'react';
import styled from 'styled-components';
import SocialIcons from './SocialIcons';

const CreditsWrapper = styled.div`
  display: flex;
  min-height: 21px;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 0px;
  font: 400 14px Abel, sans-serif;
  @media (max-width: 450px) {
    max-width: 100%;
    padding: 0 20px;
    align-items: center;
  }
`;

const CreditLink = styled.a`
display: flex;
justify-content: flex-start;
  width: 224px;
  margin-top: 8px;
  font-size: 10px;
  max-width: 100%;
  padding-bottom: 0px;
  color: #0000009d;
  text-decoration: none;
  @media (max-width: 450px) {
    display: flex;
    max-width: 100%;
    padding: 0 20px;
    justify-content: center;
  }
`;


const Credits: React.FC = () => {
  return (
    <CreditsWrapper>
      {window.innerWidth > 450 ?
       <>
       <SocialIcons />
       {/* <ProjectCredit>Manuel Guerra Pereira Fotografia</ProjectCredit> */}
       <CreditLink href="https://www.linkedin.com/in/lucas-almeida-portilho-barcelos-9a94a3136/">Made by Lucas Portilho</CreditLink>
       </>
        :
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom:'20px'}}>
        <SocialIcons />
        {/* <ProjectCredit>Manuel Guerra Pereira Fotografia</ProjectCredit> */}
        <CreditLink href="https://www.linkedin.com/in/lucas-almeida-portilho-barcelos-9a94a3136/" style={{alignItems:'center'}}>Made by Lucas Portilho</CreditLink>
        </div>
      }
     
    </CreditsWrapper>
  );
};

export default Credits;