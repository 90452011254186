import React from 'react';
import styled from 'styled-components';

interface FormInputProps {
  label: string;
  placeholder: string;
  type: string;
}

const FormInput: React.FC<FormInputProps> = ({ label, placeholder, type }) => {
  return (
    <InputWrapper>
      <InputLabel htmlFor={`input-${label}`}>{label}</InputLabel>
      <InputField
      name={`name-${label}`}
        id={`input-${label}`}
        type={type}
        placeholder={placeholder}
        aria-label={label}
      />
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
`;

const InputField = styled.input`
  border-radius: 5px;
  border: 1px solid var(--neutral-300, #d4d4d4);
  background: var(--white, #fff);
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
`;

export default FormInput;