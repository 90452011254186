import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { LanguageProvider } from './contexts/LanguageContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
  <React.StrictMode>
  <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
  </Router>
);

reportWebVitals();
