import React from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';

const LoginRegister: React.FC = () => {
  
  
  return (
    <LoginRegisterWrapper>
      <LoginContainer>
        <ImageColumn>
          <LoginImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b0d96c6afbc986aa467c6129707fc70885a646be1df7cd11ea49c86db82b034b?placeholderIfAbsent=true&apiKey=565e20570d154711a48a4ac776265d32" alt="Login illustration" />
        </ImageColumn>
        <FormColumn>
          <LoginForm />
        </FormColumn>
      </LoginContainer>
      <style>{`builder-component { max-width: none !important; }`}</style>
    </LoginRegisterWrapper>
  );
}

const LoginRegisterWrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--neutral-100, #f5f5f5);
  padding: 230px 70px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 100px 20px;
  }
`;

const LoginContainer = styled.section`
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  width: 900px;
  max-width: 100%;
  padding-right: 40px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-right: 20px;
    flex-direction: column;
  }
`;

const ImageColumn = styled.div`
  width: 55%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const LoginImage = styled.img`
  aspect-ratio: 0.8;
  object-fit: contain;
  object-position: center;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FormColumn = styled.div`
  width: 45%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

export default LoginRegister;