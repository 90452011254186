import React from 'react';
import styled from 'styled-components';
import ImageGallery from './ImageGallery';

const OverviewContainer = styled.div`
background-color: #fff;
  width: 100%;
  min-height: 100vh;
  padding: 40px 0px;
  @media (max-width: 450px) {
  padding: 0px 0px;
  }
`;

// const Title = styled.h1`
//   font-size: 32px;
//   text-align: center;
//   color: #333;
// `;

const People: React.FC = () => {
  return (
    <OverviewContainer>
      <ImageGallery />
    </OverviewContainer>
  );
};

export default People;
