import React from 'react';
import styled from 'styled-components';

const aboutImage = "https://i.ibb.co/QH6qPbp/OP7A0427.jpg";

const ImagePlaceholder: React.FC = () => {
  return <PlaceholderBox aria-label="Image placeholder" />;
};

const PlaceholderBox = styled.div`
  background-image: url(${aboutImage});
  background-size: cover; 
  min-width: 240px;
  width: 288px;
  height: 245px;
  border-radius: 4px;
  aspect-ratio: 1;
  flex-grow: 1;
`;

export default ImagePlaceholder;