import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

// Configuração do Supabase
const supabaseUrl = 'https://egrtwqlfihbbytsplqsx.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVncnR3cWxmaWhiYnl0c3BscXN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzExNTYxNzMsImV4cCI6MjA0NjczMjE3M30.hQk-d2iLOivaklScSRWVP1_wPBBRwxiXGa9LldGA-d0';
const supabase = createClient(supabaseUrl, supabaseKey);

// Styled Components
const GalleryContainer = styled.div`
  display: grid;
  padding: 20px;
`;

const ImageWrapper = styled.div<{ isVisible: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: max-content;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.9s ease, transform 0.9s ease;
  cursor: pointer;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
`;

const FullscreenContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const FullscreenImage = styled.img`
  max-height: 90vh;
  max-width: 90vw;
  object-fit: contain;
`;

const NavigationButton = styled.button`
  position: fixed;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #292929;
  font-size: 45px;
  padding: 20px;
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  bottom: 20px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const PrevButton = styled(NavigationButton)`
  right: 60px;
`;

const NextButton = styled(NavigationButton)`
  right: 10px;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 40px;
  right: 50px;
  background: none;
  border: none;
  color: #030303;
  font-size: 44px;
  cursor: pointer;
  z-index: 2001;
`;

// Componente principal
const ImageGallery: React.FC = () => {
  const [photos, setPhotos] = useState<any[]>([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    fetchPhotos();
  }, []);

  const fetchPhotos = async () => {
    try {
      const { data, error } = await supabase.from('e-commerce-db').select('*');
      if (error) throw error;
      setPhotos(data || []);
    } catch (error) {
      console.error('Erro ao buscar fotos:', error);
    }
  };

  const openFullscreen = (imageIndex: number) => {
    setCurrentImageIndex(imageIndex);
    setIsFullscreen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  const goToNextImage = () => {
    if (currentImageIndex < photos.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const goToPrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (isFullscreen) {
      if (event.key === 'ArrowRight') goToNextImage();
      if (event.key === 'ArrowLeft') goToPrevImage();
      if (event.key === 'Escape') closeFullscreen();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    //eslint-disable-next-line
  }, [isFullscreen, currentImageIndex]);

  if (isFullscreen) {
    return (
      <FullscreenContainer>
        <FullscreenImage
          src={photos[currentImageIndex].image}
          alt={photos[currentImageIndex].title}
        />
        <PrevButton
          onClick={goToPrevImage}
          disabled={currentImageIndex === 0}
        >
          &#8249;
        </PrevButton>
        <NextButton
          onClick={goToNextImage}
          disabled={currentImageIndex === photos.length - 1}
        >
          &#8250;
        </NextButton>
        <CloseButton onClick={closeFullscreen}>&times;</CloseButton>
      </FullscreenContainer>
    );
  }

  return (
    <GalleryContainer>
      <ResponsiveMasonry columnsCountBreakPoints={{ 550: 1, 950: 2, 1100: 3, 2000: 4 }}>
        <Masonry gutter="10px">
          {photos.map((photo, index) => (
            <InteractiveImage
              key={photo.id}
              title={photo.title}
              subText={photo.text}
              image={photo.image}
              onClick={() => openFullscreen(index)}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </GalleryContainer>
  );
};

const InteractiveImage: React.FC<{
  title: string;
  subText: string;
  image: string;
  onClick: () => void;
}> = ({ title, image, onClick }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.3 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ImageWrapper
      ref={ref}
      isVisible={isVisible}
      onMouseEnter={() => setIsOverlayVisible(true)}
      onMouseLeave={() => setIsOverlayVisible(false)}
      onClick={onClick}
    >
      <Image src={image} alt={title} />
      <Overlay isVisible={isOverlayVisible} />
    </ImageWrapper>
  );
};

const Overlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  border-radius: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease;
  text-align: center;
`;

export default ImageGallery;