import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MenuContent from './MenuContent';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu() {
  const [isMobileOpen, setIsMobileOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {/* Botão de menu para resoluções pequenas */}
      <Box sx={{ display: { xs: 'block', md: 'none', backgroundColor: '#121212' }, p: 1 }}>
        <IconButton onClick={toggleDrawer} aria-label="menu">
          <MenuIcon />
        </IconButton>
      </Box>

      {/* Drawer permanente para resoluções maiores */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          [`& .${drawerClasses.paper}`]: {
            backgroundColor: 'background.paper',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            mt: 'calc(var(--template-frame-height, 0px) + 4px)',
            p: 1.5,
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <AddAPhotoIcon /> <>Manager Images</>
        </Box>
        <Divider />
        <MenuContent />
        <Stack
          direction="row"
          sx={{
            p: 2,
            gap: 1,
            alignItems: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Avatar
            sizes="small"
            alt="Manuel Guerra"
            src="/static/images/avatar/7.jpg"
            sx={{ width: 36, height: 36 }}
          />
          <Box sx={{ mr: 'auto' }}>
            <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
              Manuel Guerra
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              mguerrapereira@gmail.com
            </Typography>
          </Box>
        </Stack>
      </Drawer>

      {/* Modal para resoluções menores */}
      <Modal
        open={isMobileOpen}
        onClose={toggleDrawer}
        sx={{ display: { xs: 'flex', md: 'none', color:'white' }, zIndex: 1300 }}
      >
        <Box
          sx={{
            width: drawerWidth,
            backgroundColor: 'background.paper',
            height: '100%',
            boxShadow: 24,
            position: 'relative',
            p: 2,
          }}
        >
          <IconButton
            onClick={toggleDrawer}
            sx={{ position: 'absolute', top: 8, right: 8 }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              mt: 'calc(var(--template-frame-height, 0px) + 4px)',
              p: 1.5,
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <AddAPhotoIcon /> <>Manager Images</>
          </Box>
          <Divider />
          <MenuContent />
          <Stack
            direction="row"
            sx={{
              paddingTop: 2,
              gap: 1,
              alignItems: 'center',
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Avatar
              sizes="small"
              alt="Manuel Guerra"
              src="/static/images/avatar/7.jpg"
              sx={{ width: 36, height: 36 }}
            />
            <Box sx={{ mr: 'auto' }}>
              <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                Manuel Guerra
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                mguerrapereira@gmail.com
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
