import { createGlobalStyle } from 'styled-components';


const GlobalStyles = createGlobalStyle`


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Antikor', sans-serif;
    
    color: #333;
    font-weight: 800;
    line-height: 1.6;
    
  }

  @font-face {
  font-family: 'Antikor';
  src: url('../font/AntikorTextLight.ttf') format('truetype');
}
`;

export default GlobalStyles;
