import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Pagination,
} from "@mui/material";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://egrtwqlfihbbytsplqsx.supabase.co";
const supabaseKey ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVncnR3cWxmaWhiYnl0c3BscXN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzExNTYxNzMsImV4cCI6MjA0NjczMjE3M30.hQk-d2iLOivaklScSRWVP1_wPBBRwxiXGa9LldGA-d0";
const supabase = createClient(supabaseUrl, supabaseKey);

const PhotoTable: React.FC = () => {
  const [photos, setPhotos] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editTitle, setEditTitle] = useState("");
  const [editText, setEditText] = useState("");
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 5;

  const fetchPhotos = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.from("e-commerce-db").select("*");
      if (error) throw error;
      setPhotos(data);
    } catch (error) {
      console.error("Erro ao buscar fotos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditSave = async (id: string) => {
    try {
      const { error } = await supabase
        .from("e-commerce-db")
        .update({ title: editTitle, text: editText })
        .eq("id", id);
      if (error) throw error;

      alert("Edição realizada com sucesso!");
      setEditIndex(null);
      fetchPhotos();
    } catch (error) {
      console.error("Erro ao editar:", error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase.from("e-commerce-db").delete().eq("id", id);
      if (error) throw error;

      alert("Imagem deletada com sucesso!");
      fetchPhotos();
    } catch (error) {
      console.error("Erro ao deletar:", error);
    }
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const paginatedPhotos = photos.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Box sx={{ mt: 4, px: 2 }}>
      <Typography variant="h5" gutterBottom>
        Photo Table
      </Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{ mt: 2, overflowX: "auto", maxWidth: "100%" }}
          >
             <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Photo</TableCell>
                              <TableCell align="center">Order</TableCell>
                              <TableCell align="center">Title</TableCell>
                              <TableCell align="center">Subtext</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedPhotos.map((photo, index) => (
                              <TableRow key={photo.id}>
                                <TableCell align="center">
                                  <Box
                                    component="img"
                                    src={photo.image}
                                    alt={`Photo ${index + 1}`}
                                    sx={{
                                      width: "100px",
                                      borderRadius: 1,
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {(currentPage - 1) * itemsPerPage + index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {editIndex === index ? (
                                    <input
                                      type="text"
                                      value={editTitle}
                                      onChange={(e) => setEditTitle(e.target.value)}
                                    />
                                  ) : (
                                    photo.title
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {editIndex === index ? (
                                    <input
                                      type="text"
                                      value={editText}
                                      onChange={(e) => setEditText(e.target.value)}
                                    />
                                  ) : (
                                    photo.text
                                  )}
                                </TableCell>
                             
                                <TableCell align="center">
                                  <Grid container spacing={1} justifyContent="center">
                                    {editIndex === index ? (
                                      <Grid item>
                                        <Button
                                          variant="contained"
                                          color="success"
                                          size="small"
                                          onClick={() => handleEditSave(photo.id)}
                                        >
                                          Save
                                        </Button>
                                      </Grid>
                                    ) : (
                                      <Grid item>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          onClick={() => {
                                            setEditIndex(index);
                                            setEditTitle(photo.title || "");
                                            setEditText(photo.text || "");
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      </Grid>
                                    )}
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => handleDelete(photo.id)}
                                      >
                                        Delete
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3, paddingBottom:2 }}>
            <Pagination
              count={Math.ceil(photos.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default PhotoTable;
