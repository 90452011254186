import React from 'react';
import styled from 'styled-components';

const AlertBox: React.FC = () => {
  return (
    <AlertContainer>
      <AlertIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a710bd813d2ea89c26ff1c12aa6e7642c14938578107d4900fe91abd743459f?placeholderIfAbsent=true&apiKey=565e20570d154711a48a4ac776265d32" alt="" />
      <AlertMessage>
        Attention when clicking on{" "}
        <strong>delete</strong> the photo order of the photos will be changed!
      </AlertMessage>
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  align-items: start;
  border-radius: 5px;
  border: 1px solid var(--red-400, #f87171);
  background-color: var(--red-50, #fef2f2);
  display: flex;
  margin-top: 20px;
  width: 100%;
  gap: 10px;
  font-size: 16px;
  color: var(--red-900, #7f1d1d);
  font-weight: 400;
  padding: 15px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AlertIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

const AlertMessage = styled.p`
  margin: 0;
`;

export default AlertBox;