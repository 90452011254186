import { Route, Routes, Navigate } from "react-router-dom";
// import Home from "./components/Home/index";
import About from "./components/About/index";
import Contact from "./components/Contact/index";
import RealLife from "./components/RealLife/index";
import Ecommerce from "./components/Ecommerce/index";
import Overview from "./components/Overview/index";
import Login from "./components/LoginScreen/index";
import People from "./components/People/index";
import PeopleManager from "./components/ManagerPhotoPeople/index";
import RealLifeManager from "./components/ManagerPhotoRealLife/index";
import EcommerceManager from "./components/ManagerPhotosEcommerce/index";
import EcommerceJewler from "./components/ManagerPhotoJewrly/index";
import HomeManager from "./components/ManagerPhotoHome/index";
import Jewler from "./components/Jewrly/index";
import { useState } from "react";

// Simulando estado de autenticação
function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Simula a recuperação do estado de autenticação do localStorage ou de outra fonte
    return localStorage.getItem("isAuthenticated") === "true";
  });

  return { isAuthenticated, setIsAuthenticated };
}

// Componente de rota privada
function PrivateRoute({ element }: { element: JSX.Element }) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? element : <Navigate to="/login" replace />;
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Overview/>} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/real-life" element={<RealLife />} />
      <Route path="/shop" element={<Ecommerce />} />
      <Route path="/people" element={<People />} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/jewler" element={<Jewler/>} />
      {/* Rota privada para /manager */}
      <Route path="/manager/" element={<PrivateRoute element={<HomeManager />} />} />
      <Route path="/manager/people" element={<PrivateRoute element={<PeopleManager />} />} />
      <Route path="/manager/real-life" element={<PrivateRoute element={<RealLifeManager />} />} />
      <Route path="/manager/ecommerce" element={<PrivateRoute element={<EcommerceManager />} />} />
      <Route path="/manager/jewler" element={<PrivateRoute element={<EcommerceJewler/>} />} />
    </Routes>
  );
}
