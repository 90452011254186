import React from 'react';
import Navbar from '../Navbar';
import NavbarMobile from '../NavbarMobile';
import GlobalStyles from '../../styles/GlobalStyles';
import Contact from './Contact';

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <div style={{ 
        backgroundColor: '#f5f5f5', 
        display: 'flex', 
        flexDirection: 'row', 
        height: '100vh' 
      }}>
       { window.innerWidth > 768 ? 
       <>
        <Navbar  />
        <div style={{ 
          marginLeft: '250px', 
          overflowY: 'auto', 
          flex: 1, 
          height: '100vh' 
        }}>
          
          <Contact />
        </div>
        </>
        :
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}> 
        <NavbarMobile/>
        <Contact /> 
        
        </div>
        }
      </div>
      
    </>
  );
};

export default App;
