import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { To, useNavigate } from 'react-router-dom';


const mainListItems = [
  {text: 'Home', icon: <AddToPhotosIcon />, route: '/manager/'},
  { text: 'People', icon: <AddToPhotosIcon />, route: '/manager/people' },
  { text: 'Real Life', icon: <AddToPhotosIcon />, route: '/manager/real-life' },
  { text: 'Product', icon: <AddToPhotosIcon />, route: '/manager/ecommerce' },
  { text: 'Jewler', icon: <AddToPhotosIcon />, route: '/manager/jewler' },
];

export default function MenuContent() {
  const navigate = useNavigate();

  const handleNavigation = (route: To) => {
    navigate(route);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton selected={index === 1} onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
