import React from 'react';
import styled from 'styled-components';
import { faInstagram, faFacebook, faLinkedin, faWhatsapp, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const IconContainer = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 1px;
  justify-content: center;

`;

const IconWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  height: 100%;
  width: 32px;
`;

const IconLink = styled.a`
  display: flex;
  min-height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  align-self: stretch;
  margin: auto 0;
`;

interface SocialIconProps {
  href: string;
  src: string | IconDefinition;
  alt: string;
}

const socialIcons: SocialIconProps[] = [
  { href: 'https://www.instagram.com/manuel.guerra.photography/', src: faInstagram, alt: 'Social Media Icon 1' },
  { href: 'https://www.facebook.com/manuelguerrafotografia', src: faFacebook, alt: 'Social Media Icon 2' },
  { href: 'https://pt.linkedin.com/in/manuel-guerra-pereira-0861982b', src: faLinkedin, alt: 'Social Media Icon 3' },
  {href:'https://wa.me/351918939596', src: faWhatsapp, alt: 'Social Media Icon 4'}
];

const SocialIcons: React.FC = () => {
  return (
    <IconContainer>
      {socialIcons.map((icon, index) => (
        <IconWrapper key={index}>
          <IconLink href={icon.href}>
            {typeof icon.src === 'string' ? (
              <IconImage src={icon.src} alt={icon.alt} loading="lazy" />
            ) : (
              <FontAwesomeIcon icon={icon.src} size='lg' color='#030303' />
            )}
          </IconLink>
        </IconWrapper>
      ))}
    </IconContainer>
  );
};

export default SocialIcons;