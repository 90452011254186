import React, { useState } from "react";
import axios from "axios";
import { createClient } from "@supabase/supabase-js";
import { 
  Box, 
  Button, 
  CircularProgress, 
  Grid, 
  Typography 
} from "@mui/material";

// Configuração do Supabase
const supabaseUrl = "https://egrtwqlfihbbytsplqsx.supabase.co";
const supabaseKey ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVncnR3cWxmaWhiYnl0c3BscXN4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzExNTYxNzMsImV4cCI6MjA0NjczMjE3M30.hQk-d2iLOivaklScSRWVP1_wPBBRwxiXGa9LldGA-d0";
const supabase = createClient(supabaseUrl, supabaseKey);

const AddPhotoForm: React.FC = () => {
  const [images, setImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const imgBBApiKey = "e0df9133f011ab9dbf2f68e87ae3812d";

  // Função para fazer upload da imagem no imgBB
  const uploadToImgBB = async (file: File): Promise<{ url: string; delete_url: string }> => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${imgBBApiKey}`,
        formData
      );
      
      return response.data.data;
    } catch (error) {
      console.error("Error uploading to imgBB:", error);
      throw error;
    }
  };

  // Handler para o upload das imagens
  const handleImageUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (images.length === 0) {
      alert('Please select at least one image');
      return;
    }

    setLoading(true);
    let totalUploaded = 0;

    try {
      for (const file of images) {
        const imgUrl = await uploadToImgBB(file);
        
        const { error } = await supabase.from("people-db").insert([
          {
            image: imgUrl.url,
            deleteUrl: imgUrl.delete_url
          }
        ]);

        if (error) {
            console.error("Erro ao salvar no banco de dados:", error);
          throw error;
        }

        totalUploaded++;
        setUploadProgress((totalUploaded / images.length) * 100);
      }

      setImages([]);
      alert('Images uploaded successfully!');
    } catch (error) {
      console.error("Error during upload:", error);
      alert('An error occurred during the image upload.');
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
    }
  };

  return (
    <Box
      component="section"
      sx={{
        maxWidth: 800,
        mx: "auto",
        mt: 4,
        p: 3,
        border: "1px solid #ddd",
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Add New Photos
      </Typography>
      
      <Box component="form" onSubmit={handleImageUpload}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ textTransform: "none" }}
            >
              Select Images

              <input
                type="file"
                hidden
                accept="image/*"
                multiple
                onChange={handleFileChange}
              />
            </Button>
            
            {images.length > 0 && (
              <div style={{ marginTop: '8px' }}>
                <Typography variant="body2">
                  {images.length} image(s) selected:
                </Typography>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {images.map((img, index) => (
                    <li key={index}>{img.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </Grid>

          {loading && (
            <Grid item xs={12}>
              <CircularProgress 
                variant="determinate" 
                value={uploadProgress}
                sx={{ width: '100%' }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading || images.length === 0}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Uploading..." : "+ Add"}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {uploadProgress > 0 && !loading && (
        <Typography variant="body2" align="center" style={{ marginTop: '8px' }}>
          Progresso: {Math.round(uploadProgress)}%
        </Typography>
      )}
    </Box>
  );
};

export default AddPhotoForm;