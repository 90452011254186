import React from 'react';
import styled from 'styled-components';
import FormInput from './FormInput';

const LoginForm: React.FC = () => {
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();  

    const formData = new FormData(e.target as HTMLFormElement);
    const email = formData.get('name-E-Mail') as string; 
    const password = formData.get('name-Password') as string;  

    console.log(email, password);

    if (email === 'mguerrapereira@gmail.com' && password === 'Manuel@Guerra') {
      localStorage.setItem("isAuthenticated", "true");  // Salva a autenticação
      window.location.href = '/manager';
    } else {
      alert('Invalid credentials');
    }
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <FormTitle>
        <h1>Login</h1>
        <p>Acesso ao gerenciador de images</p>
      </FormTitle>
      <FormContent>
        <FormInput  label="E-Mail" placeholder="Enter your email" type="email" />
        <FormInput  label="Password" placeholder="Enter your password" type="password" />
        {/* <ForgotPassword tabIndex={0}>Forgot password?</ForgotPassword> */}
        <LoginButton type="submit">Login</LoginButton>
      </FormContent>
    </FormWrapper>
  );
};

const FormWrapper = styled.form`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: var(--neutral-800, #262626);
`;

const FormTitle = styled.header`
  align-self: start;
  h1 {
    font-size: 22px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 300;
  }
`;

const FormContent = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  max-width: 370px;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 300;
`;

// const ForgotPassword = styled.span`
//   color: var(--neutral-600, #525252);
//   font-size: 14px;
//   font-weight: 600;
//   margin-top: 20px;
//   cursor: pointer;
// `;

const LoginButton = styled.button`
  align-self: stretch;
  border-radius: 5px;
  background: var(--blue-600, #2563eb);
  margin-top: 20px;
  width: 100%;
  font-size: 16px;
  color: var(--white, #fff);
  font-weight: 500;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: normal;
  }
`;

export default LoginForm;
