import React from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import Credits from './FooterComponents/Credits';
import { useLanguage } from '../contexts/LanguageContext';

const SidebarContainer = styled.nav`
  width: 250px;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: flex-start;
  padding: 20px;
  /* box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1); */

  left: 0;
  top: 0;
`;


const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: bold;
  color: #5e5e5e;
  margin-bottom: 10px;
`;
const Subtitle2 = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: #5e5e5e;
  margin-bottom: 10px;
`;

const SidebarLink = styled(RouterNavLink)`
  text-decoration: none;
  letter-spacing: 6px;
  color: #3d3c3c;
  font-size: 12px;
  font-weight: lighter;
  margin: 2px 0;

  &:hover {
    color: #9e9e9e;
  }
`;

const SidebarLinkAC = styled(RouterNavLink)`
  text-decoration: none;
  font-family: 'Proxima Nova', sans-serif;
  color: #3d3c3c;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: lighter;
  margin: 4px 0;

  &:hover {
    color: #9e9e9e;
  }
`;

const LanguageSwitcher = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const LanguageButton = styled.span<{ active: boolean }>`
  cursor: pointer;
  font-weight: bold;
  padding: 2px 5px 2px;
  text-align: center ;
  justify-content: center;

 
  
  margin: 0;
  border-radius: 3px;
  color: ${({ active }) => (active ? '#fff' : '#202020')};
  background-color: ${({ active }) => (active ? '#b8b7b6' : 'transparent')};

  &:hover {
    background-color: #b8b7b6;
    color: #fff;
  }
`;
const Title = styled.h1`
  font-size: 14px;
  font-weight: bold;
 line-height: 1.1;
 letter-spacing: 1px;
  color: #000000;
`;



const Sidebar: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  const texts = {
    EN: {
      people: 'PEOPLE',
      overview: 'OVERVIEW',
      realLife: 'REAL LIFE',
      shop: 'PRODUCT',
      jewler: 'JEWELRY',
      about: 'about',
      contact: 'contact',
    },
    PT: {

      people: 'PESSOAS',
      overview: 'INÍCIO',
      realLife: 'VIDA REAL',
      shop: 'PRODUTO',
      jewler: 'JOALHERIA',
      about: 'sobre',
      contact: 'contacto',
    },
  };
  return (
    <SidebarContainer>
      {/* <Logo src={logo} alt="Logo" /> */}
      <Title>MANUEL GUERRA PEREIRA</Title>
      <Title>FOTOGRAFIA</Title>
       <Subtitle>moda, retrato, reportagem, produto</Subtitle>
       <Subtitle2> Lisboa | Portugal</Subtitle2>

      
      <LanguageSwitcher>
        <LanguageButton active={language === 'EN'} onClick={() => setLanguage('EN')}>
          EN
        </LanguageButton>
        <LanguageButton active={language === 'PT'} onClick={() => setLanguage('PT')}>
          PT
        </LanguageButton>
      </LanguageSwitcher>
      <SidebarLink to="/">{texts[language].overview}</SidebarLink>
      <SidebarLink to="/people">{texts[language].people}</SidebarLink>
      <SidebarLink to="/real-life">{texts[language].realLife}</SidebarLink>
      <SidebarLink to="/jewler">{texts[language].jewler}</SidebarLink>
      <SidebarLink to="/shop">{texts[language].shop}</SidebarLink>
      <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
      <SidebarLinkAC to="/about">{texts[language].about}</SidebarLinkAC>
      <SidebarLinkAC to="/contact">{texts[language].contact}</SidebarLinkAC>
      </div>
      <Credits />
    </SidebarContainer>
  );
};

export default Sidebar;
