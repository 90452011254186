import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import AboutCard from './AboutCard';
import ImagePlaceholder from './ImagePlaceHolder';
import { useLanguage } from '../../contexts/LanguageContext'; // Importar o contexto
import Credits from '../FooterComponents/Credits';



const infoData = {
EN: [
  {
    title: 'ABOUT ME',     
    content: "Manuel Guerra Pereira photographic approach is rooted in a deep appreciation for the raw, unfiltered beauty of reality. His work is characterized by a minimalist aesthetic that emphasizes the intrinsic qualities of the subject matter. This minimalist approach allows the viewer to focus on the essence of the image, free from distractions."
  },
  {
    title: ' ',
    content: "He is committed to capturing the authentic moment, devoid of artificiality or manipulation. His photographs are a testament to the power of the unadorned image.\n By employing a minimalist approach, he strips away unnecessary elements, leaving only the essential components of the image. This simplicity allows the viewer to connect with the subject matter on a deeper level."
  },
  {
    title: ' ',
    content: "He`s work is not about creating idealized or romanticized images. Instead, he seeks to reveal the inherent beauty in the ordinary, the overlooked, and the mundane. \n Through his focus on the essential, Manuel Guerra Pereira photographs transcend fleeting trends and cultural norms, offering a timeless perspective on the human condition and the built environment."
  },
  {
    title: ' ',
    content: "He`s core philosophy remains consistent; his artistic practice has evolved over time. As his career progressed, he expanded his repertoire to include Fashion, e-commerce, portrait and artistic photography. These new directions allowed him to explore different facets of reality, while still adhering to his core principles of authenticity and simplicity."
  },
  {
    title: ' ',
    content: "Manuel Guerra Pereira photography serves as a reminder of the beauty that can be found in the ordinary. His work invites viewers to slow down, pay attention to the details, and appreciate the world around them. Through his aesthetic and unwavering commitment to authenticity, he has established himself as a significant figure in contemporary photography."
  },

],
PT: [
  {
    title: 'SOBRE MIM',
    content: "A abordagem fotográfica de Manuel Guerra Pereira está enraizada numa profunda apreciação pela beleza crua e não filtrada da realidade. O seu trabalho é caracterizado por uma estética minimalista que enfatiza as qualidades intrínsecas do assunto. Esta abordagem minimalista permite ao espectador focar-se na essência da imagem, livre de distrações."
  },
  {
    title: ' ',
    content: "Ele está comprometido em capturar o momento autêntico, desprovido de artificialidade ou manipulação. As suas fotografias são um testemunho do poder da imagem não adornada. Ao empregar uma abordagem minimalista, ele elimina elementos desnecessários, deixando apenas os componentes essenciais da imagem. Esta simplicidade permite ao espectador conectar-se com o assunto a um nível mais profundo."
  },
  {
    title: ' ',
    content: "O seu trabalho não consiste em criar imagens idealizadas ou romantizadas. Em vez disso, ele procura revelar a beleza inerente no ordinário e no mundano. \n Através do seu foco no essencial, as fotografias de Manuel Guerra Pereira transcendem tendências passageiras e normas culturais, oferecendo uma perspetiva atemporal sobre a condição humana e o ambiente construído."
  },
  {
    title: ' ',
    content: "A sua filosofia central permanece consistente; a sua prática artística evoluiu ao longo do tempo. À medida que a sua carreira progrediu, ele expandiu o seu repertório para incluir Moda, e-commerce, Retrato e Fotografia Artística. Estas novas direções permitiram-lhe explorar diferentes facetas da realidade, mantendo-se fiel aos seus princípios fundamentais de autenticidade e simplicidade."
  },
  {
    title: ' ',
    content: "A fotografia de Manuel Guerra Pereira serve como um lembrete da beleza que pode ser encontrada no ordinário. O seu trabalho convida os espectadores a abrandar, prestar atenção aos detalhes e apreciar o mundo à sua volta. Através da sua estética e do seu inabalável compromisso com a autenticidade, ele estabeleceu-se como uma figura significativa na fotografia contemporânea."
  },

],
};




const AboutSection: React.FC = () => {
  const { language } = useLanguage();

  return (
    <Section >
    <SectionWrapper>
    { window.innerWidth > 768 ?
    <div style={{display: 'flex', flexDirection: 'row', gap: '120px', justifyContent: 'center', height: '100vh'}}>
    <div style={{display: 'flex', flexDirection: 'column', gap: '40px'}}>
    <DivImageText>
          <FadeInOnScroll>
            <ImagePlaceholder />
          </FadeInOnScroll>
          <FadeInOnScroll>
            <SubText>
              <SubText>Manuel Guerra Pereira️️</SubText>
            </SubText>
          </FadeInOnScroll>
        </DivImageText>
        <FadeInOnScroll >
            <AboutCard title={infoData[language][0].title} content={infoData[language][0].content} />
          </FadeInOnScroll>
   </div>
    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
    <FadeInOnScroll >
            <AboutCard title={infoData[language][1].title} content={infoData[language][1].content} />
          </FadeInOnScroll>
          <FadeInOnScroll >
            <AboutCard title={infoData[language][2].title} content={infoData[language][2].content} />
     </FadeInOnScroll>

    </div>
    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
    <FadeInOnScroll >
            <AboutCard title={infoData[language][3].title} content={infoData[language][3].content} />
          </FadeInOnScroll>
          <FadeInOnScroll >
            <AboutCard title={infoData[language][3].title} content={infoData[language][3].content} />
     </FadeInOnScroll>
    </div>
</div>
:
<div style={{display: 'flex', flexDirection: 'column', gap: '20px', height: '100vh'}}>
    <DivImageText>
          <FadeInOnScroll>
            <ImagePlaceholder />
          </FadeInOnScroll>
          <FadeInOnScroll>
            <SubText>
              <SubTextBold>Manuel Guerra Pereira️️</SubTextBold>
            </SubText>
          </FadeInOnScroll>
        </DivImageText>
        <FadeInOnScroll >
            <AboutCard title={infoData[language][0].title} content={infoData[language][0].content} />
          </FadeInOnScroll>
          <FadeInOnScroll >
            <AboutCard title={infoData[language][1].title} content={infoData[language][1].content} />
          </FadeInOnScroll>
          <FadeInOnScroll >
            <AboutCard title={infoData[language][2].title} content={infoData[language][2].content} />
          </FadeInOnScroll>
          <FadeInOnScroll >
            <AboutCard title={infoData[language][3].title} content={infoData[language][3].content} />
          </FadeInOnScroll>
          <FadeInOnScroll >
            <AboutCard title={infoData[language][4].title} content={infoData[language][4].content} />
          </FadeInOnScroll>
          <Credits />

</div>
}

</SectionWrapper>

    </Section>
  );
};

// Componente para animar os elementos ao entrar na viewport
const FadeInOnScroll: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <FadeWrapper ref={ref} isVisible={isVisible}>
      {children}
    </FadeWrapper>
  );
};

// Estilo para a animação
const FadeWrapper = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(20px)')};
  transition: opacity 0.6s ease, transform 0.6s ease;
`;

// Estilo de layout e botões
const DivImageText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SubText = styled.p`
  color: #494949eb;
  font-size: 12px;
  font-weight: 400;
  line-height: 0;
  margin: 0;
  padding: 0;
`;

const SubTextBold = styled.p`
  color: #494949eb;
  font-size: 14px;
  font-weight: 700;
  line-height: 0;
  margin: 0;
  padding: 0;
`;

const Section = styled.section`
  display: flexbox;
  height: 100vh;
  background-color: #fff;
`;

const SectionWrapper = styled.section`
  padding: 40px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 100px;
  
`;

export default AboutSection;




