import React from 'react';
import GlobalStyles from '../../styles/GlobalStyles';
import LoginRegister from './LoginRegister';


const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
        <LoginRegister/>
       
           
    </>
  );
};

export default App;