import React from 'react';
import GlobalStyles from '../../styles/GlobalStyles';
import ManagerPhotoSection from './ManagerPhotos';





const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
        <ManagerPhotoSection  />           
    </>
  );
};

// const PhotoUploadContainer = styled.section`
//     align-items: flex-start;
//     background-color: var(--white, #fff);
//     display: flex;
//     width: 100%;
//     flex-direction: column;
//     overflow: hidden;

//     color: var(--neutral-800, #262626);
//     font-weight: 600;
//     justify-content: center;
    
    
//     @media (max-width: 991px) {
//         max-width: 100%;
//         padding: 0 20px;
//     }
//     `;

export default App;