import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import Credits from './FooterComponents/Credits';
import { useLanguage } from '../contexts/LanguageContext';

// Estilização do container principal da navbar
const NavbarContainer = styled.nav`
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  @media(max-width: 431px) {
 padding-top: 10px;
    padding: 0 10px;
  }
`;


const MenuIcon = styled.div`
  font-size: 20px;
  padding-left: 80px;
  cursor: pointer;
  @media(max-width: 431px) {
    padding-left: 0px;
  }
`;

// Modal de navegação (inicialmente oculto)
const Modal = styled.div<{ open: boolean }>`
  z-index: 10;
  position: fixed;
  top: 10;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  @media(max-width: 431px) {
    padding: 40px;
    justify-content: flex-start;
  }
 
`;

const ModalLink = styled(RouterNavLink)`
  text-decoration: none;
  color: #3d3c3c;
  font-size: 25px;
  font-weight: bold;

  &:hover {
    color: #9e9e9e;
  }
`;

// Botão para alternar idioma
const LanguageSwitcher = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  @media(max-width: 431px) {
    font-size: 10px;
    gap: 0px;
  }
`;

const LanguageButton = styled.span<{ active: boolean }>`
  cursor: pointer;
  font-weight: bold;
  padding: 3px 6px;
  border-radius: 5px;
  color: ${({ active }) => (active ? '#fff' : '#202020')};
  background-color: ${({ active }) => (active ? '#b8b7b6' : 'transparent')};

  &:hover {
    background-color: #b8b7b6;
    color: #fff;
  }
`;

const SectionOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
`;

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { language, setLanguage } = useLanguage(); // Estado para controlar o idioma

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  function redirectToHome() {
    window.location.href = '/';
  }

  function logoHomeLink() {
    setMenuOpen(false);
    redirectToHome();
  }

  // Textos para cada idioma
  const texts = {
    EN: {
      overview: 'OVERVIEW',
      people: 'PEOPLE',
      realLife: 'REAL LIFE',
      shop: 'PRODUCT',
      jewler: 'JEWELRY',
      about: 'ABOUT',
      contact: 'CONTACT',
    },
    PT: {
      overview: 'INÍCIO',
      people: 'PESSOAS',
      realLife: 'VIDA REAL',
      shop: 'PRODUTO',
      jewler: 'JOALHERIA',
      about: 'SOBRE',
      contact: 'CONTACTO',
    },
  };

  // Bloquear/desbloquear o scroll do body com base no estado do modal
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden'; // Bloqueia o scroll
    } else {
      document.body.style.overflow = 'auto'; // Restaura o scroll
    }
    return () => {
      document.body.style.overflow = 'auto'; // Limpeza quando o componente é desmontado
    };
  }, [menuOpen]);

  return (
    <>
      <NavbarContainer>
        <LanguageSwitcher>
          <LanguageButton active={language === 'EN'} onClick={() => setLanguage('EN')}>
            EN
          </LanguageButton>
          <LanguageButton active={language === 'PT'} onClick={() => setLanguage('PT')}>
            PT
          </LanguageButton>
        </LanguageSwitcher>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 20}}>
        <h1 style={{fontSize:10}} onClick={logoHomeLink}>MANUEL GUERRA PEREIRA | FOTOGRAFIA</h1>
        <h2 style={{fontSize:6}}>moda, retrato, reportagem, produto</h2> 
        <h3 style={{fontSize:8}}>Lisboa | Portugal</h3>
        </div>

        <MenuIcon onClick={toggleMenu}>&#9776; </MenuIcon>
      </NavbarContainer>
      <Modal open={menuOpen}>
        <SectionOptions>
        <LanguageSwitcher style={{marginBottom: 20}}>
          <LanguageButton active={language === 'EN'} onClick={() => setLanguage('EN')}>
            EN
          </LanguageButton>
          <LanguageButton active={language === 'PT'} onClick={() => setLanguage('PT')}>
            PT
          </LanguageButton>
        </LanguageSwitcher>
       
            <ModalLink to="/" onClick={() => setMenuOpen(false)}>
                {texts[language].overview}
            </ModalLink>
          <ModalLink to="/people" onClick={() => setMenuOpen(false)}>
            {texts[language].people}
          </ModalLink>
          <ModalLink to="/real-life" onClick={() => setMenuOpen(false)}>
            {texts[language].realLife}
          </ModalLink>
          <ModalLink to="/jewler" onClick={() => setMenuOpen(false)}>
            {texts[language].jewler}
          </ModalLink>
          <ModalLink to="/shop" onClick={() => setMenuOpen(false)}>
            {texts[language].shop}
          </ModalLink>
          <ModalLink to="/about" onClick={() => setMenuOpen(false)}>
            {texts[language].about}
          </ModalLink>
          <ModalLink to="/contact" onClick={() => setMenuOpen(false)}>
            {texts[language].contact}
          </ ModalLink>
        </SectionOptions>
        <Credits/>
      </Modal>
    </>
  );
};

export default Navbar;
